import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  DeleteScheduleInput,
  SearchCustomerScheduleInput,
  WorkingNamesInput,
  SchedulesInput,
  RegistScheduleInput,
  UpdateScheduleInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {}
}

export const state = () => defaultState()

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // ピット予約一覧
    async pitReservations(_, input: SearchCustomerScheduleInput) {
      logger.info('ピット予約一覧')
      return await getClient()
        .searchCustomerSchedules({ input })
        .then((data) => data.searchCustomerSchedules)
    },
    // 作業内容一覧
    async workingNames(_, input: WorkingNamesInput) {
      logger.info('作業内容一覧')
      return await getClient()
        .workingNames({ input })
        .then((data) => data.workingNames)
    },
    // スケジュール一覧
    async schedules(_, input: SchedulesInput) {
      logger.info('スケジュール一覧', { input })
      return await getClient()
        .schedules({ input })
        .then((data) => data.schedules)
    },
    // ピット予約変更
    async updateSchedule(_, input: UpdateScheduleInput) {
      logger.info('ピット予約変更')
      return await getClient()
        .updateSchedule({ input })
        .then((data) => data.updateSchedule)
    },
    // ピット予約削除
    async deleteSchedule(_, input: DeleteScheduleInput) {
      logger.info('ピット予約削除')
      return await getClient()
        .deleteSchedule({ input })
        .then((data) => data.deleteSchedule)
    },

    // ピット予約登録
    async registSchedule(_, input: RegistScheduleInput) {
      logger.info('ピット予約登録', { input })
      return await getClient()
        .registSchedule({
          input,
        })
        .then((data) => data.registSchedule)
    },
  }
)

export type RootState = ReturnType<typeof state>
