import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22734555 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _7adfffee = () => interopDefault(import('../src/pages/delivery-enquete/index.vue' /* webpackChunkName: "pages/delivery-enquete/index" */))
const _615aea9e = () => interopDefault(import('../src/pages/inflow/index.vue' /* webpackChunkName: "pages/inflow/index" */))
const _62e1cc86 = () => interopDefault(import('../src/pages/integrate-user-error.vue' /* webpackChunkName: "pages/integrate-user-error" */))
const _a8ad38cc = () => interopDefault(import('../src/pages/line-account-renewal.vue' /* webpackChunkName: "pages/line-account-renewal" */))
const _421e7660 = () => interopDefault(import('../src/pages/location.vue' /* webpackChunkName: "pages/location" */))
const _0f6e6f19 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _62444bb0 = () => interopDefault(import('../src/pages/not-authorized.vue' /* webpackChunkName: "pages/not-authorized" */))
const _1d76aff9 = () => interopDefault(import('../src/pages/out-of-area.vue' /* webpackChunkName: "pages/out-of-area" */))
const _03958d12 = () => interopDefault(import('../src/pages/pit-reservation/index.vue' /* webpackChunkName: "pages/pit-reservation/index" */))
const _745a0e1c = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6902de76 = () => interopDefault(import('../src/pages/quotations/index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _c7476fa0 = () => interopDefault(import('../src/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _3f38627c = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _53ae3b84 = () => interopDefault(import('../src/pages/tire-guarantee.vue' /* webpackChunkName: "pages/tire-guarantee" */))
const _344e9347 = () => interopDefault(import('../src/pages/tire-repair.vue' /* webpackChunkName: "pages/tire-repair" */))
const _401422fe = () => interopDefault(import('../src/pages/under-construction.vue' /* webpackChunkName: "pages/under-construction" */))
const _b9e372f6 = () => interopDefault(import('../src/pages/unfollow-account.vue' /* webpackChunkName: "pages/unfollow-account" */))
const _2042b966 = () => interopDefault(import('../src/pages/delivery-enquete/complete.vue' /* webpackChunkName: "pages/delivery-enquete/complete" */))
const _1726ed13 = () => interopDefault(import('../src/pages/mypage/edit.vue' /* webpackChunkName: "pages/mypage/edit" */))
const _6031321c = () => interopDefault(import('../src/pages/mypage/purchases/index.vue' /* webpackChunkName: "pages/mypage/purchases/index" */))
const _7fa22eb3 = () => interopDefault(import('../src/pages/mypage/quotations/index.vue' /* webpackChunkName: "pages/mypage/quotations/index" */))
const _0af33953 = () => interopDefault(import('../src/pages/mypage/vehicles/index.vue' /* webpackChunkName: "pages/mypage/vehicles/index" */))
const _219b58ae = () => interopDefault(import('../src/pages/pit-reservation/complete.vue' /* webpackChunkName: "pages/pit-reservation/complete" */))
const _77ce9a00 = () => interopDefault(import('../src/pages/pit-reservation/confirm.vue' /* webpackChunkName: "pages/pit-reservation/confirm" */))
const _bc4ee7da = () => interopDefault(import('../src/pages/pit-reservation/register.vue' /* webpackChunkName: "pages/pit-reservation/register" */))
const _0ea0b43e = () => interopDefault(import('../src/pages/pit-reservation/situation.vue' /* webpackChunkName: "pages/pit-reservation/situation" */))
const _cede84be = () => interopDefault(import('../src/pages/pit-reservation/store.vue' /* webpackChunkName: "pages/pit-reservation/store" */))
const _03ae2981 = () => interopDefault(import('../src/pages/pit-reservation/work.vue' /* webpackChunkName: "pages/pit-reservation/work" */))
const _975061d4 = () => interopDefault(import('../src/pages/quotations/complete.vue' /* webpackChunkName: "pages/quotations/complete" */))
const _5bd7f775 = () => interopDefault(import('../src/pages/quotations/details.vue' /* webpackChunkName: "pages/quotations/details" */))
const _a489486a = () => interopDefault(import('../src/pages/receipt/complete.vue' /* webpackChunkName: "pages/receipt/complete" */))
const _0c59f4c4 = () => interopDefault(import('../src/pages/receipt/confirm.vue' /* webpackChunkName: "pages/receipt/confirm" */))
const _31b1f047 = () => interopDefault(import('../src/pages/receipt/enquete.vue' /* webpackChunkName: "pages/receipt/enquete" */))
const _22c73c76 = () => interopDefault(import('../src/pages/receipt/qr/index.vue' /* webpackChunkName: "pages/receipt/qr/index" */))
const _60619435 = () => interopDefault(import('../src/pages/receipt/register.vue' /* webpackChunkName: "pages/receipt/register" */))
const _c0ebb20e = () => interopDefault(import('../src/pages/vehicles/registration/index.vue' /* webpackChunkName: "pages/vehicles/registration/index" */))
const _bfefb8d2 = () => interopDefault(import('../src/pages/mypage/pit-reservation/complete.vue' /* webpackChunkName: "pages/mypage/pit-reservation/complete" */))
const _12281452 = () => interopDefault(import('../src/pages/mypage/pit-reservation/confirm.vue' /* webpackChunkName: "pages/mypage/pit-reservation/confirm" */))
const _7884e210 = () => interopDefault(import('../src/pages/mypage/pit-reservation/situation.vue' /* webpackChunkName: "pages/mypage/pit-reservation/situation" */))
const _6bcdc5a2 = () => interopDefault(import('../src/pages/receipt/qr/read.vue' /* webpackChunkName: "pages/receipt/qr/read" */))
const _b697c73c = () => interopDefault(import('../src/pages/vehicles/registration/complete.vue' /* webpackChunkName: "pages/vehicles/registration/complete" */))
const _2df753b2 = () => interopDefault(import('../src/pages/vehicles/registration/confirm.vue' /* webpackChunkName: "pages/vehicles/registration/confirm" */))
const _f56cb3ec = () => interopDefault(import('../src/pages/vehicles/registration/qr.vue' /* webpackChunkName: "pages/vehicles/registration/qr" */))
const _795b485c = () => interopDefault(import('../src/pages/vehicles/registration/select-type.vue' /* webpackChunkName: "pages/vehicles/registration/select-type" */))
const _d58ab772 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _28a1c168 = () => interopDefault(import('../src/pages/mypage/pit-reservation/_id.vue' /* webpackChunkName: "pages/mypage/pit-reservation/_id" */))
const _d945e8f8 = () => interopDefault(import('../src/pages/mypage/purchases/_id.vue' /* webpackChunkName: "pages/mypage/purchases/_id" */))
const _174241db = () => interopDefault(import('../src/pages/mypage/quotations/_id.vue' /* webpackChunkName: "pages/mypage/quotations/_id" */))
const _2a95747b = () => interopDefault(import('../src/pages/mypage/vehicles/_id.vue' /* webpackChunkName: "pages/mypage/vehicles/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact/",
    component: _22734555,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/delivery-enquete/",
    component: _7adfffee,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete"
  }, {
    path: "/inflow/",
    component: _615aea9e,
    pathToRegexpOptions: {"strict":true},
    name: "inflow"
  }, {
    path: "/integrate-user-error/",
    component: _62e1cc86,
    pathToRegexpOptions: {"strict":true},
    name: "integrate-user-error"
  }, {
    path: "/line-account-renewal/",
    component: _a8ad38cc,
    pathToRegexpOptions: {"strict":true},
    name: "line-account-renewal"
  }, {
    path: "/location/",
    component: _421e7660,
    pathToRegexpOptions: {"strict":true},
    name: "location"
  }, {
    path: "/mypage/",
    component: _0f6e6f19,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/not-authorized/",
    component: _62444bb0,
    pathToRegexpOptions: {"strict":true},
    name: "not-authorized"
  }, {
    path: "/out-of-area/",
    component: _1d76aff9,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-area"
  }, {
    path: "/pit-reservation/",
    component: _03958d12,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation"
  }, {
    path: "/privacy-policy/",
    component: _745a0e1c,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy"
  }, {
    path: "/quotations/",
    component: _6902de76,
    pathToRegexpOptions: {"strict":true},
    name: "quotations"
  }, {
    path: "/receipt/",
    component: _c7476fa0,
    pathToRegexpOptions: {"strict":true},
    name: "receipt"
  }, {
    path: "/terms/",
    component: _3f38627c,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tire-guarantee/",
    component: _53ae3b84,
    pathToRegexpOptions: {"strict":true},
    name: "tire-guarantee"
  }, {
    path: "/tire-repair/",
    component: _344e9347,
    pathToRegexpOptions: {"strict":true},
    name: "tire-repair"
  }, {
    path: "/under-construction/",
    component: _401422fe,
    pathToRegexpOptions: {"strict":true},
    name: "under-construction"
  }, {
    path: "/unfollow-account/",
    component: _b9e372f6,
    pathToRegexpOptions: {"strict":true},
    name: "unfollow-account"
  }, {
    path: "/delivery-enquete/complete/",
    component: _2042b966,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-complete"
  }, {
    path: "/mypage/edit/",
    component: _1726ed13,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-edit"
  }, {
    path: "/mypage/purchases/",
    component: _6031321c,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases"
  }, {
    path: "/mypage/quotations/",
    component: _7fa22eb3,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations"
  }, {
    path: "/mypage/vehicles/",
    component: _0af33953,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles"
  }, {
    path: "/pit-reservation/complete/",
    component: _219b58ae,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-complete"
  }, {
    path: "/pit-reservation/confirm/",
    component: _77ce9a00,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-confirm"
  }, {
    path: "/pit-reservation/register/",
    component: _bc4ee7da,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-register"
  }, {
    path: "/pit-reservation/situation/",
    component: _0ea0b43e,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-situation"
  }, {
    path: "/pit-reservation/store/",
    component: _cede84be,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-store"
  }, {
    path: "/pit-reservation/work/",
    component: _03ae2981,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-work"
  }, {
    path: "/quotations/complete/",
    component: _975061d4,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-complete"
  }, {
    path: "/quotations/details/",
    component: _5bd7f775,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-details"
  }, {
    path: "/receipt/complete/",
    component: _a489486a,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-complete"
  }, {
    path: "/receipt/confirm/",
    component: _0c59f4c4,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-confirm"
  }, {
    path: "/receipt/enquete/",
    component: _31b1f047,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-enquete"
  }, {
    path: "/receipt/qr/",
    component: _22c73c76,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr"
  }, {
    path: "/receipt/register/",
    component: _60619435,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-register"
  }, {
    path: "/vehicles/registration/",
    component: _c0ebb20e,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration"
  }, {
    path: "/mypage/pit-reservation/complete/",
    component: _bfefb8d2,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-complete"
  }, {
    path: "/mypage/pit-reservation/confirm/",
    component: _12281452,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-confirm"
  }, {
    path: "/mypage/pit-reservation/situation/",
    component: _7884e210,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-situation"
  }, {
    path: "/receipt/qr/read/",
    component: _6bcdc5a2,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr-read"
  }, {
    path: "/vehicles/registration/complete/",
    component: _b697c73c,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-complete"
  }, {
    path: "/vehicles/registration/confirm/",
    component: _2df753b2,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-confirm"
  }, {
    path: "/vehicles/registration/qr/",
    component: _f56cb3ec,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-qr"
  }, {
    path: "/vehicles/registration/select-type/",
    component: _795b485c,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-select-type"
  }, {
    path: "/",
    component: _d58ab772,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/mypage/pit-reservation/:id?/",
    component: _28a1c168,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-id"
  }, {
    path: "/mypage/purchases/:id/",
    component: _d945e8f8,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases-id"
  }, {
    path: "/mypage/quotations/:id/",
    component: _174241db,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations-id"
  }, {
    path: "/mypage/vehicles/:id/",
    component: _2a95747b,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
