import { render, staticRenderFns } from "./ElmErrorView.vue?vue&type=template&id=08c4a2f6"
import script from "./ElmErrorView.vue?vue&type=script&lang=ts"
export * from "./ElmErrorView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomErrorTitle: require('/codebuild/output/src4105078729/src/beeline-line/frontend-liff/src/components/AtomErrorTitle.vue').default,AtomErrorDescription: require('/codebuild/output/src4105078729/src/beeline-line/frontend-liff/src/components/AtomErrorDescription.vue').default})
